import { makeAutoObservable } from 'mobx';
import { type TFunction } from 'i18next';

import { LocalStorageService } from 'src/data/services/local-storage/local-storage.service';
import { LocalizeService } from 'src/data/services/localize/localize.service';
import { PendoService } from 'src/data/services/pendo/pendo.service';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { UserStore } from 'src/data/stores/user/user.store';
import { Language } from 'src/domain/models/locale/locale.model';
import i18n from 'src/resources/translations/i18next';
import { doNothing } from 'src/utils/function.utils';
import { handleRequest } from 'src/utils/handle-request.utils';

export type TranslationFn = TFunction<'translation', undefined>;
export interface TranslationFeature {
    setLanguage: (lang: Language) => void;
    language: Language;
}

export class TranslationFeatureImpl implements TranslationFeature {
    localLanguage: Language = Language.En;

    get language() {
        const { user } = this.userStore;
        return user ? user.language : this.localLanguage;
    }

    onSetLanguage = (language: Language) => {
        if (this.userStore.user) {
            this.userStore.user.language = language;
            this.pendoService.updateLocale(language);
        }
    };

    setLanguage = (language: Language) => {
        this.localStorageService.setLanguage(language);
        i18n.changeLanguage(language);
        this.localLanguage = language;

        if (this.userStore.user) {
            handleRequest(
                this.localizeService.setLanguage,
                { language },
                () => this.onSetLanguage(language),
                doNothing,
                (error) =>
                    this.baseStore.onRequestFailed(
                        'set-localization-language',
                        error,
                    ),
            );
        }
    };

    constructor(
        private localizeService: LocalizeService,
        private localStorageService: LocalStorageService,
        private userStore: UserStore,
        private pendoService: PendoService,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
        this.initializeLanguage();
    }

    private initializeLanguage = () => {
        this.localLanguage = this.localStorageService.getLanguage();
        i18n.changeLanguage(this.localLanguage);
    };
}
