import { observer } from 'mobx-react';
import { FC, useState } from 'react';

import { SearchType } from 'src/app-features/searches-configuration/domain/models/configured-search';
import { BuildSearchUrl } from 'src/app-features/searches-configuration/presentation/utils/utils';
import { TranslationFn } from 'src/domain/features/translation/translation.feature';
import { User } from 'src/domain/models/user/user.model';
import { AppRoute } from 'src/presentation/modules/router/app-route.list';
import { Link } from 'src/presentation/shared/link/link.component';

import { CircleX, MoreHorizontal, Search } from 'lucide-react';
import {
    Popover,
    PopoverClose,
    PopoverTrigger,
} from '@buildingradar/ui_library/popover';
import { getRoot } from 'src/utils/element.utils';
import { cn } from 'src/utils/tailwindcss.utils';

interface SubMenuComponentProps {
    t: TranslationFn;
    user: User;
}

export const SubMenuComponent: FC<SubMenuComponentProps> = observer(
    ({ t, user }) => {
        const shouldShowSearches =
            !user.featureToggle.inboxOnly || user.isImpersonated;

        const [isSubMenuOpened, setIsSubMenuOpened] = useState(false);

        return (
            <Popover
                side="right"
                container={getRoot()}
                onOpenChange={setIsSubMenuOpened}
                sideOffset={20}
                className="flex w-fit flex-col py-3"
                trigger={
                    <PopoverTrigger>
                        <div className="bg-navbar group flex h-16 w-full cursor-pointer items-center justify-center p-1">
                            <MoreHorizontal
                                className={cn('group-hover:text-indigo-600', {
                                    'text-indigo-600': isSubMenuOpened,
                                })}
                            />
                        </div>
                    </PopoverTrigger>
                }
            >
                <ul className="font-medium">
                    <li className="hover:text-indigo-600">
                        <Link
                            className="disqualified"
                            href={AppRoute.disqualified}
                        >
                            <PopoverClose className="flex items-center gap-2 px-4">
                                <CircleX className="size-4" />
                                {t('deal_view.state.disqualified')}
                            </PopoverClose>
                        </Link>
                    </li>
                    {shouldShowSearches && (
                        <>
                            <hr className="my-2 border border-gray-200" />
                            <li className="hover:text-indigo-600">
                                <Link
                                    className="configure-searches"
                                    href={BuildSearchUrl(SearchType.Article)}
                                >
                                    <PopoverClose className="flex items-center gap-2 px-4">
                                        <Search className="size-4" />
                                        {t('navbar.configure_searches')}
                                    </PopoverClose>
                                </Link>
                            </li>
                        </>
                    )}
                </ul>
            </Popover>
        );
    },
);
