import { type FC } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, X } from 'lucide-react';
import { Button } from '@buildingradar/ui_library/button';
import { NotificationsTabContainer } from './notifications-tab.container';

type TProps = {
    toggleNotificationsOpened: (isOpen: boolean) => void;
};

export const NotificationsPanel: FC<TProps> = observer(
    ({ toggleNotificationsOpened }) => {
        const { t } = useTranslation();

        const handleClose = () => toggleNotificationsOpened(false);

        return (
            <div className="notifications-list flex h-full flex-col gap-4 overflow-hidden bg-slate-100">
                <div className="flex h-12 shrink-0 items-center justify-start gap-2 px-6 pt-4 sm:px-8 md:justify-between">
                    <Button
                        className="-ml-1"
                        variant="ghost"
                        size="icon"
                        color="secondary"
                        onClick={handleClose}
                    >
                        <ChevronLeft className="size-7" />
                    </Button>
                    <h2 className="truncate text-xl font-medium md:text-2xl">
                        {t('navbar.notifications')}
                    </h2>
                    <Button
                        className="hidden md:inline-flex"
                        variant="ghost"
                        size="icon"
                        color="secondary"
                        onClick={handleClose}
                    >
                        <X />
                    </Button>
                </div>
                <div className="grow overflow-hidden">
                    <NotificationsTabContainer />
                </div>
            </div>
        );
    },
);
