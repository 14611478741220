import { type TFunction } from 'i18next';

import { ToasterMessage } from 'src/domain/features/toaster/toaster.utils';
import { DealOpenSource } from 'src/domain/models/deal/deal.model';
import { Lead } from 'src/domain/models/lead/lead.model';
import { User } from 'src/domain/models/user/user.model';
import { createPipelineRoute } from 'src/presentation/modules/pipeline/pipeline.utils';
import {
    createDealPageLink,
    getSearchTagFromLocation,
} from 'src/presentation/modules/router/utils/route.utils';

const numberOfMessages = 14;

export const createDealSuccessMessage = (
    dealId: string,
    pipelineId: string,
    t: TFunction,
): ToasterMessage => {
    return {
        title: t('screening.deal_creation_toaster.title'),
        message: t(
            `screening.deal_creation_toaster.${`message_${getRandomInt(numberOfMessages)}`}` as string,
        ),
        autoClose: 6000,
        primaryLink: createDealPageLink(
            dealId,
            DealOpenSource.CreatedDealToaster,
        ),
        primaryLinkText: t('screening.deal_creation_toaster.go_to_deal'),
        secondaryLink: createPipelineRoute(pipelineId),
        secondaryLinkText: t('screening.deal_creation_toaster.go_to_pipeline'),
    };
};

export const createSuccessMovedMessage = (t: TFunction): ToasterMessage => ({
    title: t('screening.move-lead.disqualify-success-message'),
});

export const getSearchNames = (lead: Lead, user?: User): string[] => {
    const { searchIds } = lead;
    const urlSearchId = parseInt(getSearchTagFromLocation(window.location));
    const searchIdList = isNaN(urlSearchId) ? searchIds : [urlSearchId];
    const searchNames =
        user?.searches
            .filter(({ id }) => searchIdList?.includes?.(id))
            .map(({ name }) => name) ?? [];

    return searchNames;
};

const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * max);
};
