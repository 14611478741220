import { makeAutoObservable } from 'mobx';
import { type TFunction } from 'i18next';
import { getI18n } from 'react-i18next';

import { AchievementService } from 'src/app-features/achievements/domain/service/achievements.service';
import { ReminderResponse } from 'src/data/api/actions/reminders/api-responses';
import { IRemindersApi } from 'src/data/api/actions/reminders/reminders.interface';
import { UserAchievementName } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { DealsStore } from 'src/data/stores/deals/deals.store';
import { ErrorsStore } from 'src/data/stores/errors/errors.store';
import { NotificationsStore } from 'src/data/stores/notifications/notifications.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { ToasterStore } from 'src/data/stores/toaster/toaster.store';
import { TargetTypeModelToSdkMap } from 'src/domain/models/reminder/reminder-target-type';
import {
    CreateReminderData,
    Reminder,
    UpdateReminderData,
} from 'src/domain/models/reminder/reminder.model';
import { handleRequestAsync } from 'src/utils/handle-request.utils';

import {
    IRemindersFeature,
    ReminderFlyoutDataContext,
} from './reminders-interface.feature';

const REMINDER_CREATION_ERROR_IDENTIFIER = 'create-reminder';
const REMINDER_UPDATE_ERROR_IDENTIFIER = 'update-reminder';
const REMINDER_DISMISS_ERROR_IDENTIFIER = 'dismiss-reminder';

export class RemindersFeature implements IRemindersFeature {
    t: TFunction<'translation', undefined> = getI18n().t;

    isFlyoutOpened = false;
    flyoutDataContext?: ReminderFlyoutDataContext;

    constructor(
        private errorsStore: ErrorsStore,
        private toasterStore: ToasterStore,
        private remindersApi: IRemindersApi,
        private notificationsStore: NotificationsStore,
        private dealsStore: DealsStore,
        private baseStore: IBaseStore,
        private achievementService: AchievementService,
    ) {
        makeAutoObservable(this);
    }

    /**
     * We open the reminder flyout and set the data context.
     * In some cases, the reminder will be
     * @param dataContext
     */
    openReminderFlyout = (dataContext: ReminderFlyoutDataContext) => {
        this.isFlyoutOpened = true;
        this.flyoutDataContext = dataContext;
    };

    closeReminderFlyout = () => {
        this.isFlyoutOpened = false;
        this.flyoutDataContext = undefined;
    };

    onReminderCreated = (
        value: ReminderResponse,
        errorKeyToClear: string,
        dealId?: string,
        onSuccess?: (reminder: Reminder) => void,
        displaySuccessToaster = true,
    ) => {
        const newReminder = new Reminder(value);
        this.onReminderCreatedOrUpdated(errorKeyToClear, displaySuccessToaster);
        if (dealId) {
            const deal = this.dealsStore.dealsMap.get(dealId);
            if (deal) {
                deal.reminders = deal?.reminders
                    ? [...deal.reminders, newReminder]
                    : [newReminder];
                this.dealsStore.dealsMap.set(dealId, { ...deal });
            }
        }
        this.notificationsStore.addCreatedReminder(newReminder);
        onSuccess?.(newReminder);

        this.achievementService.call(UserAchievementName.PlanANextStep);
    };

    onReminderUpdated =
        (
            errorKeyToClear: string,
            reminderId: string,
            dealId?: string,
            onSuccess?: (reminder: Reminder) => void,
            displaySuccessToaster = true,
        ) =>
        (value: ReminderResponse) => {
            const newReminder = new Reminder(value);
            this.onReminderCreatedOrUpdated(
                errorKeyToClear,
                displaySuccessToaster,
            );
            if (dealId) {
                const deal = this.dealsStore.dealsMap.get(dealId);
                if (deal?.reminders) {
                    deal.reminders = deal.reminders.map((reminder) => {
                        if (reminder.id === reminderId) {
                            return newReminder;
                        }
                        return reminder;
                    });

                    this.dealsStore.dealsMap.set(dealId, deal);
                }
            }
            this.notificationsStore.updateReminder(newReminder);
            onSuccess?.(newReminder);
        };

    onReminderCreatedOrUpdated = (
        errorKeyToClear: string,
        displaySuccessToaster = true,
    ) => {
        this.errorsStore.clearError(errorKeyToClear);

        /**
         * This line is important as we need to re-fetch the alert from the server
         * after creating/updating a reminder to get a fresh alert type considering the hierarch
         */
        this.dealsStore.setNeedToSyncDealAlert(true);

        if (displaySuccessToaster) {
            this.toasterStore.showMessage({
                title: this.t(
                    'actions.reminders.reminder_set_successfully_message',
                ),
                type: 'success',
            });
        }
    };

    onReminderCreationOrUpdatedFailed = (errorKey: string, error?: Error) => {
        this.baseStore.onRequestFailed(errorKey, error, {
            errorMessage: this.t(
                'actions.reminders.reminder_set_failed_message',
            ),
        });
    };

    createReminder = async (
        data: CreateReminderData,
        dealId?: string,
        onSuccess?: (reminder: Reminder) => void,
        displaySuccessToaster = true,
    ) => {
        try {
            const createdReminder = await handleRequestAsync(
                this.remindersApi.createReminder,
                {
                    ...data,
                    description: this.buildReminderDescription(
                        data.description,
                    ),
                    target: {
                        ...data.target,
                        type: TargetTypeModelToSdkMap[data.target.type],
                    },
                },
            );

            if (createdReminder) {
                this.onReminderCreated(
                    createdReminder,
                    REMINDER_CREATION_ERROR_IDENTIFIER,
                    dealId,
                    onSuccess,
                    displaySuccessToaster,
                );
            }
        } catch (error) {
            this.onReminderCreationOrUpdatedFailed(
                REMINDER_CREATION_ERROR_IDENTIFIER,
                error as Error,
            );
        }
    };

    updateReminder = async (
        reminderId: string,
        data: UpdateReminderData,
        dealId?: string,
        onSuccess?: (reminder: Reminder) => void,
        displaySuccessToaster = true,
    ) => {
        try {
            const updatedReminder = await handleRequestAsync(
                this.remindersApi.updateReminder,
                {
                    ...data,
                    reminderId,
                    description: this.buildReminderDescription(
                        data.description,
                    ),
                },
            );

            if (updatedReminder) {
                this.onReminderUpdated(
                    REMINDER_UPDATE_ERROR_IDENTIFIER,
                    reminderId,
                    dealId,
                    onSuccess,
                    displaySuccessToaster,
                )(updatedReminder);
            }
        } catch (error) {
            this.onReminderCreationOrUpdatedFailed(
                REMINDER_UPDATE_ERROR_IDENTIFIER,
                error as Error,
            );
        }
    };

    onReminderCompleted = (
        reminderId: string,
        dealId: string,
        completedOn: Date,
        onSuccess?: () => void,
        displaySuccessToaster = true,
    ) => {
        if (reminderId) {
            this.notificationsStore.dismissReminder(reminderId);
            if (displaySuccessToaster) {
                this.toasterStore.showMessage({
                    title: this.t(
                        'actions.reminders.reminder_dismissed_successfully_message',
                    ),
                    type: 'success',
                });
            }
            this.errorsStore.clearError(REMINDER_DISMISS_ERROR_IDENTIFIER);
            const deal = this.dealsStore.dealsMap.get(dealId ?? '');

            /**
             * This line is important as we need to re-fetch the alert from the server
             * after dismissing a reminder to get a fresh alert type considering the hierarch
             */
            if (deal) {
                this.dealsStore.setNeedToSyncDealAlert(true);
            }

            if (deal && deal.reminders) {
                const completedReminder = deal.reminders.find(
                    ({ id }) => id === reminderId,
                );

                if (completedReminder) {
                    completedReminder.completedOn = new Date(completedOn);
                }

                this.dealsStore.dealsMap.set(deal.id, { ...deal });
            }

            if (onSuccess) {
                onSuccess();
            }

            this.achievementService.call(UserAchievementName.ResolveANextStep);
        }
    };

    markAsCompleted = async (
        reminderId: string,
        dealId: string,
        onSuccess?: () => void,
        displaySuccessToaster = true,
    ) => {
        try {
            const completedReminder = await handleRequestAsync(
                this.remindersApi.dismissReminder,
                { reminderId },
            );

            if (completedReminder?.completedDate) {
                this.onReminderCompleted(
                    completedReminder.id,
                    dealId,
                    completedReminder.completedDate,
                    onSuccess,
                    displaySuccessToaster,
                );
            }
        } catch (error) {
            this.baseStore.onRequestFailed(
                REMINDER_DISMISS_ERROR_IDENTIFIER,
                error as Error,
            );
        }
    };

    buildReminderDescription = (rawDescription?: string) => {
        return rawDescription !== undefined && rawDescription.length > 0
            ? rawDescription
            : this.t('actions.reminders.note_default_text');
    };
}
