import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './app';
import { initializeSentry } from './sentry/instrument';
import { getRoot } from './utils/element.utils';
import { checkWebSocket } from './utils/websocket.utils';
import './resources/translations/i18next';
import './index.css';

void initializeSentry();
void checkWebSocket();

const container = getRoot();
const root = createRoot(container as HTMLElement);

root.render(
    <StrictMode>
        <Suspense fallback="Loading translations, please wait">
            <App />
        </Suspense>
    </StrictMode>,
);
