import { observer } from 'mobx-react';
import { type ReactElement, type ReactNode } from 'react';
import { type TFunction } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';

interface LocalizedProps {
    children: string | ((t: TFunction<'translation'>) => ReactElement);
    placeholderMap?: Record<string, unknown>;
    components?: { [tagName: string]: ReactNode } & {
        [tagName: string]: ReactElement;
    };
    shouldUnescape?: boolean;
}

export const Localized = observer((props: LocalizedProps) => {
    const { t, i18n } = useTranslation();

    const { children, placeholderMap, shouldUnescape } = props;

    if (typeof children === 'function') {
        return children(t);
    }

    return (
        <Trans
            i18n={i18n}
            i18nKey={children}
            values={placeholderMap}
            components={props.components}
            shouldUnescape={shouldUnescape}
        />
    );
});
