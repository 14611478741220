import { observer } from 'mobx-react';
import { FC, useCallback, useEffect } from 'react';

import { NotificationsPanelContainer } from './components/notifications-panel/notifications-panel.container';
import { getRoot } from 'src/utils/element.utils';
import { SheetDrawer } from '@buildingradar/ui_library/sheet';

interface NotificationsFlyoutProps {
    toggleNotificationsOpened: (flag: boolean) => void;
    isOpened: boolean;
}

export const NotificationsFlyout: FC<NotificationsFlyoutProps> = observer(
    ({ toggleNotificationsOpened, isOpened }) => {
        useEffect(() => {
            return () => toggleNotificationsOpened(false);
        }, [toggleNotificationsOpened]);

        const toggleOpened = useCallback(() => {
            toggleNotificationsOpened(!isOpened);
        }, [isOpened, toggleNotificationsOpened]);

        return (
            <SheetDrawer
                side="left"
                container={getRoot()}
                onOpenChange={toggleOpened}
                open={isOpened}
                allowOutsideInteraction={false}
                overlayClassName="left-16 z-notifications-overlay"
                showOverlay
                className="w-notifications-flyout z-notifications ml-16 flex h-full rounded-r-2xl shadow-sm"
            >
                <NotificationsPanelContainer />
            </SheetDrawer>
        );
    },
);
