import { makeAutoObservable } from 'mobx';
import { type TFunction } from 'i18next';
import { getI18n } from 'react-i18next';

import { ISearchesApi } from 'src/app-features/searches-configuration/api/searches.api';
import {
    removeEmptyCriteriaFromSearchFilterObject,
    syncInMemorySearchAfterUnlinkingFilter,
} from 'src/app-features/searches-configuration/domain/features/shared';
import { FilterLocation } from 'src/app-features/searches-configuration/domain/models/filter-location';
import {
    FilterModuleConfigOption,
    UpsertFilterModule,
} from 'src/app-features/searches-configuration/domain/models/filter-module-config-option';
import {
    TenderChildCode,
    TenderRootCode,
} from 'src/app-features/searches-configuration/domain/models/tender-code';
import { IFilterModuleConfigStore } from 'src/app-features/searches-configuration/domain/stores/filter-module-configuration/store.interface';
import { getFilterModuleConfigOptionsByType } from 'src/app-features/searches-configuration/domain/utils/filter-config-utils';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { SubscriptionsStore } from 'src/data/stores/subscriptions/subscriptions.store';
import { UserStore } from 'src/data/stores/user/user.store';
import {
    FilterModuleTypeToSdkMap,
    FilterModule,
    FilterModuleType,
} from 'src/domain/models/filter-module/filter-module.model';
import { TreeNode } from 'src/presentation/shared/br-tree-view-selector/tree-node.models';
import { doNothing } from 'src/utils/function.utils';
import { handleRequestAsync } from 'src/utils/handle-request.utils';
import { KeyValuePair } from 'src/utils/type.utils';

import { IFilterModuleConfigFeature } from './feature.interface';

export class FilterModuleConfigFeature implements IFilterModuleConfigFeature {
    requestingFilterModules = false;
    savingFilterModule = false;
    filterOptions: FilterModuleConfigOption<unknown>[] = [];

    t: TFunction<'translation', undefined> = getI18n().t;

    constructor(
        private filterModuleConfigStore: IFilterModuleConfigStore,
        private userStore: UserStore,
        private subscriptionsStore: SubscriptionsStore,
        private searchesApi: ISearchesApi,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    get filterModulesMap(): Map<number, FilterModule> | undefined {
        return this.filterModuleConfigStore.filterModulesMap;
    }

    requestFilterModules = async () => {
        try {
            const filterModules = await handleRequestAsync(
                this.searchesApi.getFilterModules,
                {},
                (loading) =>
                    this.onRequestLoading(loading, 'requestingFilterModules'),
            );

            if (filterModules) {
                this.filterModuleConfigStore.filterModulesMap = new Map<
                    number,
                    FilterModule
                >();
                filterModules.forEach((filter) => {
                    this.filterModuleConfigStore.filterModulesMap!.set(
                        filter.id,
                        filter,
                    );
                });
            }
        } catch (error) {
            this.baseStore.onRequestFailed(
                'request-filter-modules',
                error as Error,
            );
        }
    };

    onRequestLoading = (
        loading: boolean,
        flag: 'requestingFilterModules' | 'savingFilterModule',
    ) => {
        this[flag] = loading;
    };

    initializeFilterModuleConfigOptions = (type: FilterModuleType) => {
        const options = getFilterModuleConfigOptionsByType(
            type,
            {
                locationFetcher: this.requestSuggestedLocation,
                domainSourcesFetcher: this.requestSuggestedDomainSources,
                rootTenderCodesFetcher: this.requestTenderCodeTrees,
                childrenTenderCodesFetcher: this.requestTenderChildCodeTrees,
                filteredTenderCodesFetcher:
                    this.requestFilteredTenderCodesTrees,
            },
            this.t,
        );
        this.filterOptions = options;
    };

    requestSuggestedLocation = async (
        partialText: string,
    ): Promise<FilterLocation[]> => {
        const rawLocationsData = await handleRequestAsync(
            this.searchesApi.getFilterLocations,
            {
                partialText,
            },
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed(
                    'request-suggested-locations',
                    error,
                ),
        );

        /**
         * We do this here since the key for a search location is
         * the stringified version of the location object. (Don't ask me why)
         */
        return (rawLocationsData ?? []).map((location) => ({
            ...location,
            key: JSON.stringify(location),
        }));
    };

    requestSuggestedDomainSources = async (
        partialText: string,
    ): Promise<KeyValuePair<string>[]> => {
        const sources = await handleRequestAsync(
            this.searchesApi.getDomainSources,
            {
                partialText,
            },
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed(
                    'request-suggested-domain-sources',
                    error,
                ),
        );

        return (sources ?? []).map((source) => ({
            key: source,
            value: source,
        }));
    };

    mapRootCodeToTreeNode = (root: TenderRootCode): TreeNode => ({
        id: root.id,
        name: root.name,
        hasChildren: root.hasChildren,
        numberOfChildren: root.children.length,
        children: root.children.map(this.mapChildCodeToTreeNode),
    });

    mapChildCodeToTreeNode = (child: TenderChildCode): TreeNode => ({
        id: child.id,
        name: child.name,
        hasChildren: child.hasChildren,
        numberOfChildren: child.numberOfChildren,
        parent: child.parent,
        children: child.children?.map(this.mapChildCodeToTreeNode),
    });

    requestTenderCodeTrees = async (): Promise<TreeNode[]> => {
        const rootCodes = await handleRequestAsync(
            this.searchesApi.getTenderRootCodes,
            {},
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed('request-tender-code', error),
        );

        const nodes: TreeNode[] = (rootCodes ?? []).map(
            this.mapRootCodeToTreeNode,
        );
        return nodes;
    };

    requestFilteredTenderCodesTrees = async (
        textSearch: string,
    ): Promise<TreeNode[]> => {
        const filteredRootCodes = await handleRequestAsync(
            this.searchesApi.getFilteredTenderCodes,
            { textSearch },
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed(
                    'request-filtered-tender-codes',
                    error,
                ),
        );

        const filteredNodes: TreeNode[] = (filteredRootCodes ?? []).map(
            this.mapRootCodeToTreeNode,
        );
        return filteredNodes;
    };

    requestTenderChildCodeTrees = async (code: string): Promise<TreeNode[]> => {
        const childCodes = await handleRequestAsync(
            this.searchesApi.getChildTenderCodes,
            { code },
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed(
                    'request-tender-child-code',
                    error,
                ),
        );

        const childTreeNodes: TreeNode[] = (childCodes ?? []).map(
            this.mapChildCodeToTreeNode,
        );

        return childTreeNodes;
    };

    upsertFilterModule = async (
        upsertPayload: UpsertFilterModule,
    ): Promise<number | undefined> => {
        const { id, name, filterCriteria, filterModuleType } = upsertPayload;

        let filter: FilterModule | undefined;
        try {
            if (!id) {
                filter = await handleRequestAsync(
                    this.searchesApi.createFilterModule,
                    {
                        name,
                        type: FilterModuleTypeToSdkMap[filterModuleType],
                        data: removeEmptyCriteriaFromSearchFilterObject(
                            filterCriteria,
                        ),
                    },
                    (loading) =>
                        this.onRequestLoading(loading, 'savingFilterModule'),
                );
            } else {
                filter = await handleRequestAsync(
                    this.searchesApi.updateFilterModule,
                    {
                        id,
                        name,
                        data: removeEmptyCriteriaFromSearchFilterObject(
                            filterCriteria,
                        ),
                    },
                    (loading) =>
                        this.onRequestLoading(loading, 'savingFilterModule'),
                );
            }

            if (filter) {
                this.filterModuleConfigStore.filterModulesMap!.set(
                    filter.id,
                    filter,
                );
            }
            return filter?.id;
        } catch (error) {
            this.baseStore.onRequestFailed(
                'upsert-filter-module',
                error as Error,
            );
        }
    };

    deleteFilterModule = async (filterToDelete: FilterModule) => {
        const { id, linkedSearchIds } = filterToDelete;

        try {
            await this.removeFilterFromLinkedSearches(id, linkedSearchIds);

            await handleRequestAsync(
                this.searchesApi.deleteFilterModule,
                { id },
                (loading) =>
                    this.onRequestLoading(loading, 'savingFilterModule'),
            );

            this.filterModuleConfigStore.filterModulesMap?.delete(id);
        } catch (error) {
            this.baseStore.onRequestFailed(
                'delete-filter-module',
                error as Error,
            );
        }
    };

    removeFilterFromLinkedSearches = async (
        filterId: number,
        appliedToSearchIds: number[],
    ) => {
        if (!appliedToSearchIds.length) {
            return;
        }

        const removeRequests: Promise<number>[] = [];
        appliedToSearchIds.forEach((searchId) => {
            const subscription =
                this.subscriptionsStore.subscriptions.get(searchId);
            if (!subscription) {
                return;
            }

            removeRequests.push(
                this.searchesApi.upsertSearch({
                    id: subscription.id,
                    name: subscription.name,
                    type: subscription.type,
                    filterModuleIds: subscription.filterModuleIds.filter(
                        (id) => id !== filterId,
                    ),
                    signal: new AbortController().signal,
                }),
            );
        });

        const responses = await Promise.all(removeRequests);

        responses.forEach((searchId) => {
            this.syncInMemorySubscriptionForRemovedFilter(filterId, searchId);
            const search = this.userStore.user?.searches.find(
                (s) => s.id === searchId,
            );
            syncInMemorySearchAfterUnlinkingFilter(filterId, search);
        });

        if (responses.length !== appliedToSearchIds.length) {
            throw new Error(
                'Failed to remove the filter from some or all linked searches',
            );
        }
    };

    syncInMemorySubscriptionForRemovedFilter = (
        filterId: number,
        searchSubscriptionId: number,
    ) => {
        const subscription =
            this.subscriptionsStore.subscriptions.get(searchSubscriptionId);
        if (subscription) {
            subscription.filterModuleIds = subscription.filterModuleIds.filter(
                (id) => id !== filterId,
            );
        }
    };
}
