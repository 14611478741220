import { makeAutoObservable } from 'mobx';
import { type TFunction } from 'i18next';
import { getI18n } from 'react-i18next';

import { IDeveloperApiKeysApi } from 'src/app-features/account-developer-tools/data/api/developer-api-keys.api';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import {
    handleRequest,
    handleRequestAsync,
} from 'src/utils/handle-request.utils';

import { DeveloperApiKey } from './developer-api-key.model';

export interface IAccountDeveloperToolsFeature {
    apiKeys?: DeveloperApiKey[];
    isLoading: boolean;
    fetchCurrentUserApiKeys: () => void;
    createNewApiKey: (
        keyName: string,
        writeAccess: boolean,
    ) => Promise<string | undefined>;
    deleteApiKey: (id: number) => void;
}

export class AccountDeveloperToolsFeature
    implements IAccountDeveloperToolsFeature
{
    t: TFunction<'translation', undefined> = getI18n().t;
    apiKeys?: DeveloperApiKey[] = undefined;
    isLoading = false;

    constructor(
        private developerApiKeysApi: IDeveloperApiKeysApi,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    setIsLoadingStatus = (flag: boolean) => {
        this.isLoading = flag;
    };

    fetchCurrentUserApiKeys = () => {
        const onApiKeysLoaded = (keys: DeveloperApiKey[]) => {
            this.apiKeys = keys.sort((a, b) =>
                b.createdOn.localeCompare(a.createdOn),
            );
        };

        /**
         * Only fetch the api key once. After that, we only update the api keys
         * array when adding or removing a key
         */
        if (!this.apiKeys) {
            handleRequest(
                this.developerApiKeysApi.myKeys,
                {},
                onApiKeysLoaded,
                this.setIsLoadingStatus,
                (error) =>
                    this.baseStore.onRequestFailed(
                        'get-current-user-api-keys',
                        error,
                    ),
            );
        }
    };

    createNewApiKey = async (keyName: string, writeAccess: boolean) => {
        const trimmedKey = keyName.trim();

        const result = await handleRequestAsync(
            this.developerApiKeysApi.createNew,
            { keyName: trimmedKey, writeAccess },
            this.setIsLoadingStatus,
            this.onApiKeyCreationFailed,
        );
        if (result) {
            const lastPlainTextKeyCreated = result.plainTextKey;
            this.onApiKeyAdded(result);
            return lastPlainTextKeyCreated;
        }
    };

    onApiKeyAdded = (newApiKey: DeveloperApiKey) => {
        newApiKey.plainTextKey = undefined;
        this.apiKeys = [newApiKey, ...(this.apiKeys ?? [])];
    };

    onApiKeyCreationFailed = (error?: Error) => {
        this.baseStore.onRequestFailed('create-new-api-key', error, {
            errorTitle: this.t(
                'account.developer_tools.api_keys.creation_failed.title',
            ),
            errorMessage: this.t(
                'account.developer_tools.api_keys.creation_failed.message',
            ),
        });
    };

    deleteApiKey = (id: number) => {
        handleRequest(
            this.developerApiKeysApi.deleteKey,
            { id },
            () => this.onApiKeyDeleted(id),
            this.setIsLoadingStatus,
            (error) => this.baseStore.onRequestFailed('delete-api-key', error),
        );
    };

    onApiKeyDeleted = (id: number) => {
        this.apiKeys = this.apiKeys?.filter((key) => key.id !== id);
    };
}
