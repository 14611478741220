import { BrDatePickerLocale } from '@buildingradar/br_component_lib';
import { add, format } from 'date-fns';
import { useEffect, useState } from 'react';
import { type TFunction } from 'i18next';

import {
    BrDatePrecision,
    BrDatePrecisionSdk,
    BrDateTime,
    BrDateTimeSdk,
    LocaleDateOptions,
} from 'src/domain/models/br-time/br-time.model';
import {
    Language,
    languageToDateFnsLocale,
    languageToLocale,
} from 'src/domain/models/locale/locale.model';
import { TranslationFn } from 'src/resources/translations/i18next';

const UPDATE_DATE_AGO_INTERVAL = 60000;

const units: { unit: Intl.RelativeTimeFormatUnit; ms: number }[] = [
    { unit: 'year', ms: 31536000000 },
    { unit: 'month', ms: (24 * 60 * 60 * 1000 * 365) / 12 },
    { unit: 'day', ms: 86400000 },
    { unit: 'hour', ms: 3600000 },
    { unit: 'minute', ms: 60000 },
    { unit: 'second', ms: 1000 },
];

const createRtf = (language: Language) => {
    return new Intl.RelativeTimeFormat(language, {
        localeMatcher: 'best fit',
        numeric: 'always',
        style: 'long',
    });
};

const getTimeAgo = (rtf: Intl.RelativeTimeFormat, diffInMs: number) => {
    for (const { unit, ms } of units) {
        if (Math.abs(diffInMs) >= ms || unit === 'second') {
            const signValue = diffInMs >= 0 ? 1 : -1;
            return rtf.format(
                signValue * Math.floor(Math.abs(diffInMs) / ms),
                unit,
            );
        }
    }
    return '';
};

const getDaysAgo = (rtf: Intl.RelativeTimeFormat, days: number) => {
    // if {days} are in negative it means its future date
    // rtf format takes negative days as previous days
    const signValue = -1;
    return rtf.format(signValue * days, 'day');
};
const formatDateAgo = (date: Date, language: Language): string => {
    const currentDate = new Date();

    const diffInMs = date.getTime() - currentDate.getTime();
    const rtf = createRtf(language);
    return getTimeAgo(rtf, diffInMs);
};

export const getNumberOfDaysAgo = (date: Date): number => {
    const currentDate = new Date();

    const diffInMs = currentDate.getTime() - date.getTime();

    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    return diffInDays;
};

/**
 * a hook for showing date in 'ago' format
 * it will be updated automatically every UPDATE_DATE_AGO_INTERVAL milliseconds
 *
 * @param date
 * @param language
 * @param updateIntervalMs
 */
export const useUpdatableDateAgo = (
    date: Date,
    language: Language,
    updateIntervalMs: number = UPDATE_DATE_AGO_INTERVAL,
): string => {
    const [formatted, setFormatted] = useState(formatDateAgo(date, language));

    useEffect(() => {
        const interval = setInterval(() => {
            const newFormatted = formatDateAgo(date, language);
            setFormatted(newFormatted);
        }, updateIntervalMs);

        return () => {
            clearInterval(interval);
        };
    }, [date, language, updateIntervalMs]);
    return formatted;
};

export const getDateObject = (
    date: BrDateTime | BrDateTimeSdk,
): Date | null => {
    if (date.precision !== BrDatePrecision.Day) {
        return null;
    }
    return new Date(
        date.year,
        date.month - 1,
        date.day,
        date.hour,
        date.minute,
        date.second,
        date.microsecond / 1000,
    );
};

export const getDateSdkObject = (date: BrDateTimeSdk): Date | null => {
    if (date.precision !== BrDatePrecisionSdk.Day) {
        return null;
    }
    return new Date(
        date.year,
        date.month - 1,
        date.day,
        date.hour,
        date.minute,
        date.second,
        date.microsecond / 1000,
    );
};

/**
 * Format a Date object to a string containing the date part, following the locale rules
 * @param date Date object to format
 * @param language The language to use for the formatting
 * @param precision The precision of the date to format
 * @returns A string representing the date. Eg.: MM/dd/yyyy
 */
export const dateToString = (
    date: Date,
    language: Language,
    precision?: BrDatePrecision,
): string => {
    return dateAndTimeToString(date, language, precision).split(',')[0];
};

/**
 * Format a Date object to a string containing date and time, following the locale rules
 * @param date Date object to format
 * @param language The language to use for the formatting
 * @param precision The precision of the date to format
 * @returns A string representing the date. Eg.: MM/dd/yyyy, hh:mm
 */
export const dateAndTimeToString = (
    date: Date,
    language: Language,
    precision?: BrDatePrecision,
): string => {
    const locale = languageToLocale[language];
    return date.toLocaleDateString(
        locale,
        LocaleDateOptions[precision ?? BrDatePrecision.DateTime],
    );
};

export const formatDate = (
    date: Date | null | undefined,
    displayFormat: string,
    language: Language,
): string | null => {
    if (!date) return null;

    const locale = languageToDateFnsLocale[language];
    return format(date, displayFormat, { locale });
};

export const getDateWeeklyFormat = (date: Date, language: Language): string => {
    const numberOfDays = getNumberOfDaysAgo(date);
    if (numberOfDays <= 7) {
        const rtf = new Intl.RelativeTimeFormat(language, { numeric: 'auto' });
        return getDaysAgo(rtf, numberOfDays);
    }
    const locale = languageToLocale[language];
    return date.toLocaleDateString(
        locale,
        LocaleDateOptions[BrDatePrecision.Day],
    );
};

/**
 * Checks that date is created correctly
 * @param date
 */
export const isDateValid = (date: Date): boolean => {
    return !isNaN(date.getTime());
};

const getLocalIsoString = (date: Date): string => {
    const tzoffset = date.getTimezoneOffset() * 60000;
    const localISOTime = new Date(date.getTime() - tzoffset).toISOString();
    return localISOTime;
};

/**
 * Format Date to DD-MM-YYYY format
 * @param date - date
 */
export const formatDateToDayMonthYear = (
    date: Date,
    joinChar = '-',
): string => {
    return getLocalIsoString(date)
        .slice(0, 10)
        .split('-')
        .reverse()
        .join(joinChar);
};

export const formatDateToISO = (date: Date): string => {
    return `${date.toISOString().slice(0, -1)}+00:00`;
};

export const getExportDate = (
    language: Language,
    exportedDate: Date | null,
    sentDate?: Date,
) => {
    return sentDate
        ? dateToString(new Date(sentDate), language, BrDatePrecision.Day)
        : exportedDate
          ? dateToString(new Date(exportedDate), language, BrDatePrecision.Day)
          : null;
};

/**
 * Returns the date of tomorrow, taking into account the today's date
 */
export const getTomorrow = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
};

/**
 * Converts a Date object to a yyyy-MM-dd string
 */
export const formatToApiDateFormat = (date: Date) => {
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 1);
    return date.toISOString().slice(0, 10);
};

export const isDateOverdue = (date: Date) => date <= new Date();

export const getTime = () => {
    return new Date().getTime();
};

export const DAYS = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
];

export const addMonthsToDate = (date: Date, monthsToAdd: number) =>
    add(date, { months: monthsToAdd });

const millisecondsToDays = (ms: number): number => {
    return Math.floor(ms / (1000 * 60 * 60 * 24));
};

/**
 * It formats a given date in a relative way.
 * @param date The date to be be used as a base for the calculation against Date.Today
 * @param t The translation fn used by the app
 * @param language The current language used by the user
 * @param invertBy Signal to invert the format. -1 returns `x days ago`, +1 returns `in x days`
 * @param todayText An optional translation for when the relative date returns 0 days difference
 * @returns The relative date, eg.: `1 hour ago`, `3 days ago` `in 1 week`, `in 5 days`
 */
export const formatRelativeDate = (args: {
    date: Date;
    t: TFunction<'translation', undefined>;
    language?: Language;
    enableToday?: boolean;
    todayText?: string;
    ignoreHours?: boolean;
    invertBy?: number;
}) => {
    const {
        date,
        t,
        language = Language.En,
        enableToday = true,
        todayText = 'common.today',
        ignoreHours = false,
        invertBy = -1,
    } = args;

    const currentDate = new Date();
    if (ignoreHours) {
        currentDate.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0);
    }
    const differenceInMilliseconds = currentDate.getTime() - date.getTime();
    const numberOfDays = millisecondsToDays(differenceInMilliseconds);

    if (numberOfDays === 0 && enableToday) {
        return t(todayText);
    }

    const rtf = createRtf(language ?? Language.En);
    return getTimeAgo(rtf, differenceInMilliseconds * invertBy);
};

export const getLocaleForDatePicker = (language: Language) =>
    language === Language.En ? BrDatePickerLocale.enEu : BrDatePickerLocale.de;

export const getDateRangeWithLabels = (
    startValue: BrDateTime | BrDateTimeSdk | null | undefined,
    endValue: BrDateTime | BrDateTimeSdk | null | undefined,
    t: TranslationFn,
) => {
    if (!startValue && !endValue) {
        return null;
    }

    const startText = 'date_range_picker.starts';
    const endText = 'date_range_picker.ends';
    const bothText = 'date_range_picker.until';

    if (startValue && !endValue) {
        return `${t('lead.document_type.project')} ${t(startText)} ${startValue.year}`;
    }

    if (!startValue && endValue) {
        return `${t('lead.document_type.project')} ${t(endText)} ${endValue.year}`;
    }

    return `${startValue?.year ?? ''} ${t(bothText)} ${endValue?.year ?? ''}`;
};

export const getLocaleTextFromDate = (date: Date, language: Language) => {
    const formatString =
        language === Language.En ? 'MMM dd yyyy' : 'dd. MMM yyyy';
    return format(date, formatString, {
        locale: languageToDateFnsLocale[language],
    });
};
