import { type FC, useEffect } from 'react';
import { Bell, BellRing, HelpCircleIcon, Settings } from 'lucide-react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@buildingradar/ui_library/tooltip';
import { Button } from '@buildingradar/ui_library/button';
import { BrIcon, IconName } from '@buildingradar/br_component_lib';
import { useAuthFeature } from 'src/app-context/use-features';
import { useFeatureFlagBool } from 'src/utils/hooks/use-feature-flag';
import { cn } from 'src/utils/tailwindcss.utils';
import { type Mention } from 'src/domain/models/mention/mention.model';
import { type Reminder } from 'src/domain/models/reminder/reminder.model';
import { FeatureFlag } from 'src/data/services/feature-flags/feature-flags.model';
import { NavItemComponent } from 'src/presentation/modules/primary-nav-bar/components/nav-item/nav-item.component';
import { AppRoute } from 'src/presentation/modules/router/app-route.list';
import {
    useAnchorTag,
    useCurrentRoute,
} from 'src/presentation/modules/router/utils/use-route-data.hook';
import { OtherRoutes, readableOtherRoutes } from './other-section.util';

const NOTIFICATIONS_HASH_URL = 'notifications';

type TProps = {
    overdueReminders: Reminder[];
    unreadMentions: Mention[];
    areNotificationsOpened: boolean;
    toggleNotifications: (flag: boolean) => void;
};

export const OtherSectionComponent: FC<TProps> = observer(
    ({
        overdueReminders,
        unreadMentions,
        areNotificationsOpened,
        toggleNotifications,
    }) => {
        const { t } = useTranslation();
        const currentRoute = useCurrentRoute();
        const { user } = useAuthFeature();
        const shouldOpenNotifications = useAnchorTag(NOTIFICATIONS_HASH_URL);
        const isNotificationManagementFeatureEnabled = useFeatureFlagBool(
            FeatureFlag.notificationsManagement,
        );

        useEffect(() => {
            if (shouldOpenNotifications) {
                toggleNotifications(true);
            }
        }, [shouldOpenNotifications, toggleNotifications]);

        if (!user) return null;

        const notificationCount =
            overdueReminders.length + unreadMentions.length;
        const isGeneralSearchActive = currentRoute === AppRoute.generalSearch;
        const isSettingsActive =
            currentRoute?.startsWith(AppRoute.settings) ?? false;

        return (
            <div className="bg-navbar-active w-full">
                <NavItemComponent
                    className="project-lookup"
                    name={t(readableOtherRoutes[OtherRoutes.searches])}
                    icon={
                        <BrIcon
                            iconName={IconName.BrSearchProjectsIcon}
                            iconSize={32}
                            iconColor={
                                isGeneralSearchActive
                                    ? 'var(--accent-link)'
                                    : 'var(--main-font)'
                            }
                        />
                    }
                    route={AppRoute.generalSearch}
                    isActive={isGeneralSearchActive}
                    isShadowVisible={areNotificationsOpened}
                />
                <Tooltip
                    side="right"
                    triggerAsChild
                    content={t('navbar.notifications')}
                >
                    <Button
                        variant="ghost"
                        className="notifications group bg-navbar-default relative size-16 rounded-none p-1"
                        onClick={() => {
                            toggleNotifications(!areNotificationsOpened);
                        }}
                    >
                        {isNotificationManagementFeatureEnabled
                            ? !!notificationCount && (
                                  <div className="absolute top-1 right-2 flex min-h-5 min-w-5 items-center justify-center rounded-full bg-red-500 px-1.5 text-xs text-white shadow-sm">
                                      {notificationCount > 99
                                          ? '99+'
                                          : notificationCount}
                                  </div>
                              )
                            : !!notificationCount && (
                                  <div className="absolute top-3 right-3 size-2 rounded-full bg-red-500" />
                              )}
                        <div
                            className={cn(
                                'absolute left-full h-0 w-0 border-t-[16px] border-r-[13px] border-b-[14px] border-t-transparent border-r-slate-100 border-b-transparent opacity-0 duration-150 ease-out md:transition-all',
                                areNotificationsOpened &&
                                    'left-[80%] opacity-100',
                            )}
                        />
                        {areNotificationsOpened ? (
                            <BellRing className="size-7 text-indigo-600 [&>path]:fill-indigo-600" />
                        ) : (
                            <Bell className="size-7 text-gray-800 group-hover:text-indigo-600" />
                        )}
                    </Button>
                </Tooltip>
                <Tooltip
                    side="right"
                    content={t('navbar.resource_center')}
                    triggerAsChild
                >
                    <Button
                        asChild
                        variant="ghost"
                        className=":hovr group bg-navbar-default size-16 rounded-none p-1"
                    >
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            className="resource-center-link"
                            href={t('navbar.resource_center.link')}
                        >
                            <HelpCircleIcon className="size-7 text-gray-800 group-hover:text-indigo-600" />
                        </a>
                    </Button>
                </Tooltip>
                <NavItemComponent
                    className="settings group"
                    name={t(readableOtherRoutes[OtherRoutes.settings])}
                    icon={
                        <Settings
                            className={cn(
                                'size-7 text-gray-800 group-hover:text-indigo-600',
                                {
                                    'text-indigo-600': isSettingsActive,
                                },
                            )}
                        />
                    }
                    route={AppRoute.settings}
                    isActive={isSettingsActive}
                    isShadowVisible={areNotificationsOpened}
                />
            </div>
        );
    },
);
