import { css, Global } from '@emotion/react';
import { fontStyles } from './font-styles';
import { themeStyles } from './theme-styles';

const styles = css`
    ${fontStyles};
    ${themeStyles};
`;

export const GlobalStyles = () => <Global styles={styles} />;
