import { type TFunction } from 'i18next';

import { FilterModuleType } from 'src/domain/models/filter-module/filter-module.model';
import { TreeNode } from 'src/presentation/shared/br-tree-view-selector/tree-node.models';

import { SearchFilterCriteria } from './configured-search';

export enum FilterOperation {
    or = 'or',
    and = 'and',
    not = 'not',
    exact = 'exact',
    notExact = 'not exact',
}

export const getFilterOperationLocalizationMap = (
    t: TFunction<'translation', undefined>,
): { [key in FilterOperation]: string } => ({
    [FilterOperation.or]: 'oder',
    [FilterOperation.and]: 'und',
    [FilterOperation.not]: t(
        'search_configuration.filter_edition.criteria_operator.not',
    ),
    [FilterOperation.exact]: t(
        'search_configuration.filter_edition.criteria_operator.exact',
    ),
    [FilterOperation.notExact]: t(
        'search_configuration.filter_edition.criteria_operator.not_exact',
    ),
});

interface ExtraConfigFields {
    [key: string]: unknown;
}

export interface FilterModuleConfigOption<T = unknown> {
    name: string;
    label: string;
    type: FilterModuleType;
    extraFields?: ExtraConfigFields;
    defaultOperation: FilterOperation;
    allowedOperations?: { operation: FilterOperation; color: string }[];
    uiType:
        | 'freeTextCriteria'
        | 'multiselectCriteria'
        | 'autocompleteCriteria'
        | 'switchCriteria'
        | 'periodRangeCriteria'
        | 'treeViewCriteria';
    uiDataContext?: T;
    isDisabledCallback?: (
        searchFilterCriteriaContext: SearchFilterCriteria,
    ) => boolean;
}

export interface MultiselectDataContext {
    options: {
        value: string;
        label: string;
        children?: { value: string; label: string }[];
    }[];
}

export interface AutocompleteDataContext<
    TResult extends { key: string; value: string },
> {
    requestCallback: (searchTerm: string) => Promise<TResult[]>;
}

export interface PeriodRangeDataContext {
    fromLabel: string;
    fromOptions: { value: number; label: string }[];
    toLabel: string;
    toOptions: { value: number; label: string }[];
}

export interface SwitchDataContext {
    enabledValue: string;
}

export interface TreeViewDataContext {
    rootRequestCallback: () => Promise<TreeNode[]>;
    childrenRequestCallback: (nodeId: string) => Promise<TreeNode[]>;
    filteredChildrenRequestCallback: (
        searchTerm: string,
    ) => Promise<TreeNode[]>;
}

export interface UpsertFilterModule {
    id: number;
    name: string;
    filterCriteria: SearchFilterCriteria;
    filterModuleType: FilterModuleType;
}
