import { type TFunction } from 'i18next';

import { Language } from 'src/domain/models/locale/locale.model';
import { Reminder } from 'src/domain/models/reminder/reminder.model';
import { formatRelativeDate } from 'src/utils/datetime.utils';

export const formatReminderDueDate = (
    dueDate: Date,
    t: TFunction<'translation', undefined>,
    language?: Language,
    todayText = 'actions.reminders.reminder_due_today_label',
) => {
    return formatRelativeDate({
        date: dueDate,
        t,
        language,
        todayText,
        ignoreHours: true,
    });
};

export const sortUncompletedRemindersByDueDateAsc = (reminders: Reminder[]) => {
    return reminders
        .filter(({ completedOn }) => !completedOn)
        .sort((a, b) => a.dueDate.getTime() - b.dueDate.getTime());
};
