import * as Sentry from '@sentry/react';
import { type TFunction } from 'i18next';
import { getI18n } from 'react-i18next';

import { ErrorsStore } from 'src/data/stores/errors/errors.store';
import { ToasterStore } from 'src/data/stores/toaster/toaster.store';
import { isAbortError } from 'src/utils/handle-request.utils';

import { IBaseStore, RequestErrorHandleParams } from './base.store.interface';
import { doNothing } from 'src/utils/function.utils';

/**
 * This is a base store class with can be composed with other stores in order to provide
 * shared methods. For instance, error handling.
 */
export class BaseStore implements IBaseStore {
    constructor(
        private errorsStore: ErrorsStore,
        private toasterStore: ToasterStore,
    ) {}

    /**
     * Shared function which can be used to handle any kind of errors happening iat the level of
     * our features. For instance, can be used to handle request errors.
     * @param errorKey The error key to be handle
     * @param error The error object
     * @param errorParams An optional object error title for the Toaster
     */
    onRequestFailed = (
        errorKey: string,
        error?: Error,
        errorParams?: RequestErrorHandleParams,
    ): void => {
        this.errorsStore.handle(errorKey)(error);

        if (error && !isAbortError(error)) {
            const params = this.buildDefaultErrorParams(errorParams);

            if (params?.defaultErrorToasterEnabled) {
                const t: TFunction<'translation', undefined> = getI18n().t;
                this.toasterStore.showMessage({
                    title: params?.errorTitle ?? t('common.error'),
                    message: params?.errorMessage ?? t('common.error_occurred'),
                    type: 'error',
                    autoClose: params?.autoClose ?? 5000,
                });
            }

            if (params?.enableSentryErrorCapture) {
                try {
                    error.name = errorKey;
                } catch {
                    doNothing();
                }
                /*
                 * After handling the error, we need to tell Sentry to capture it
                 *  */
                Sentry.captureException(error);
            }
        }
    };

    private buildDefaultErrorParams = (
        passedParams?: RequestErrorHandleParams,
    ): RequestErrorHandleParams | undefined => {
        const params = passedParams ? { ...passedParams } : {};
        if (params) {
            if (params.defaultErrorToasterEnabled === undefined) {
                params.defaultErrorToasterEnabled = true;
            }

            if (params.enableSentryErrorCapture === undefined) {
                params.enableSentryErrorCapture = true;
            }
        }

        return params;
    };
}
