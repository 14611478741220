import { type FC, type ReactNode, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { motion } from 'motion/react';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from '@buildingradar/ui_library/tabs';
import { FeatureFlag } from 'src/data/services/feature-flags/feature-flags.model';
import { type Mention } from 'src/domain/models/mention/mention.model';
import { type Reminder } from 'src/domain/models/reminder/reminder.model';
import { useFeatureFlagBool } from 'src/utils/hooks/use-feature-flag';
import { type NotificationsFeature } from 'src/domain/features/notifications/notifications-interface.feature';
import { RemindersDashboardContainer } from '../reminders-dashboard/reminders-dashboard.container';
import { MentionsDashboardContainer } from '../mentions-dashboard/mentions-dashboard.container';

enum ETab {
    Reminders = 'reminders',
    Mentions = 'mentions',
}

const Tab: FC<{ tab: ETab; children: ReactNode }> = ({ tab, children }) => (
    <TabsContent
        className="h-full w-full grow overflow-y-auto overscroll-y-contain px-9 pt-2 pb-4 sm:px-11"
        value={tab}
    >
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            {children}
        </motion.div>
    </TabsContent>
);

const Counter = ({ count }: { count: number }) => {
    return (
        <div className="ml-2 flex min-h-5 min-w-5 items-center justify-center rounded-full bg-red-500 px-1.5 text-xs text-white">
            {count > 99 ? '99+' : count}
        </div>
    );
};

type TProps = {
    overdueReminders: Reminder[];
    unreadMentions: Mention[];
    fetchRemindersAndMentions: NotificationsFeature['fetchRemindersAndMentions'];
};

export const NotificationsTab: FC<TProps> = observer(
    ({ overdueReminders, unreadMentions, fetchRemindersAndMentions }) => {
        const { t } = useTranslation();
        const isNotificationManagementFeatureEnabled = useFeatureFlagBool(
            FeatureFlag.notificationsManagement,
        );

        useEffect(() => {
            void fetchRemindersAndMentions();
        }, [fetchRemindersAndMentions]);

        const overdueReminderCount = overdueReminders.length;
        const unreadMentionCount = unreadMentions.length;

        return (
            <Tabs
                className="flex h-full flex-col items-start"
                defaultValue={ETab.Reminders}
            >
                <TabsList className="shrink-0 px-6 sm:px-8">
                    <TabsTrigger
                        className="hover:text-primary-600 bg-transparent"
                        value={ETab.Reminders}
                    >
                        {t('notification_flyout.reminders_tab.tab_title')}
                        {isNotificationManagementFeatureEnabled &&
                            !!overdueReminderCount && (
                                <Counter count={overdueReminderCount} />
                            )}
                    </TabsTrigger>
                    <TabsTrigger
                        className="hover:text-primary-600 bg-transparent"
                        value={ETab.Mentions}
                    >
                        {t('notification_flyout.mentions_tab.tab_title')}
                        {isNotificationManagementFeatureEnabled &&
                            !!unreadMentionCount && (
                                <Counter count={unreadMentionCount} />
                            )}
                    </TabsTrigger>
                </TabsList>
                <Tab tab={ETab.Reminders}>
                    <RemindersDashboardContainer />
                </Tab>
                <Tab tab={ETab.Mentions}>
                    <MentionsDashboardContainer />
                </Tab>
            </Tabs>
        );
    },
);
