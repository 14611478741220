import { makeAutoObservable } from 'mobx';
import { type TFunction } from 'i18next';
import { getI18n } from 'react-i18next';

import { CommentsApi } from 'src/app-features/deal-comments/data/comments.api';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { CommentsStore } from 'src/app-features/deal-comments/store/comments.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { ToasterStore } from 'src/data/stores/toaster/toaster.store';
import { UserStore } from 'src/data/stores/user/user.store';
import { Colleague } from 'src/domain/models/colleague/colleague.model';
import {
    Comment,
    ParsedComment,
} from 'src/app-features/deal-comments/domain/model/comment.model';
import { Deal } from 'src/domain/models/deal/deal.model';
import {
    Cancellable,
    handleRequest,
    handleRequestAsync,
} from 'src/utils/handle-request.utils';
import { isNonNullable } from 'src/utils/is-non-nullable.utils';
import { doNothing } from 'src/utils/function.utils';

export type AddCommentFn = (
    mentionsData: ParsedComment,
    deal: Deal,
    onSuccess?: () => void,
) => void;

export type EditCommentFn = (
    mentionsData: ParsedComment,
    deal: Deal,
    commentId: string,
    onSuccess?: () => void,
) => void;

export interface CommentsFeature {
    comments: Comment[];
    colleagues: Colleague[];
    preFilledCommentText: string;
    setPreFilledCommentText: (text: string) => void;
    editComment: EditCommentFn;
    addComment: AddCommentFn;
    deleteComment: (commentId: string) => Promise<boolean>;
    isLoading: boolean;
    requestComments: (dealId: string) => Cancellable;
}

export class CommentsFeatureImpl implements CommentsFeature {
    t: TFunction<'translation', undefined> = getI18n().t;

    get colleagues(): Colleague[] {
        return this.userStore.user?.colleagues ?? [];
    }

    get isLoading(): boolean {
        return this.commentsStore.isLoading;
    }

    get isModifyCommentLoading(): boolean {
        return this.commentsStore.isModifyCommentLoading;
    }

    get comments(): Comment[] {
        return this.commentsStore.orderedCommentIds
            .map((id) => this.commentsStore.commentsMap.get(id))
            .filter(isNonNullable);
    }

    get preFilledCommentText(): string {
        return this.commentsStore.preFilledCommentText;
    }

    setPreFilledCommentText = (text: string) => {
        this.commentsStore.setPreFilledCommentText(text);
    };

    constructor(
        private commentsApi: CommentsApi,
        private commentsStore: CommentsStore,
        private userStore: UserStore,
        private toasterStore: ToasterStore,
        private mixpanelService: MixpanelService,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    requestComments = (dealId: string): Cancellable => {
        this.commentsStore.clear();
        return handleRequest(
            this.commentsApi.getComments,
            { dealId },
            this.commentsStore.setComments,
            this.commentsStore.setLoading,
            (error) =>
                this.baseStore.onRequestFailed('request-comments', error),
        );
    };

    addComment = (
        mentionsData: ParsedComment,
        deal: Deal,
        onSuccess?: () => void,
    ) => {
        const { textWithMentions } = mentionsData;
        handleRequest(
            this.commentsApi.addComment,
            {
                dealId: deal.id,
                content: textWithMentions,
            },
            (comment) => this.onCreateOrEditResponse(deal, comment, onSuccess),
            doNothing,
            (error) => this.onCreateOrEditError('add-comment', error),
        );
    };

    editComment = (
        mentionsData: ParsedComment,
        deal: Deal,
        commentId: string,
        onSuccess?: () => void,
    ) => {
        const { textWithMentions, mentions } = mentionsData;

        handleRequest(
            this.commentsApi.editComment,
            {
                content: textWithMentions,
                notifiedUserIds: mentions,
                commentId,
            },
            (comment) => this.onCreateOrEditResponse(deal, comment, onSuccess),
            doNothing,
            (error) => this.onCreateOrEditError('edit-comment', error),
        );
    };

    onCreateOrEditResponse = (
        deal: Deal,
        comment: Comment | undefined,
        onSuccess?: () => void,
    ) => {
        if (comment) {
            this.commentsStore.setComment(comment);
            this.mixpanelService.trackEvent(
                MixpanelEventName.SubmittedComment,
                {
                    dealId: deal.id,
                    pipelineName: deal.pipeline.name,
                    pipelineId: deal.pipeline.id,
                    comment: comment.text,
                },
                deal.id,
            );
            onSuccess?.();
        }
    };

    onCreateOrEditError = (errorKey: string, error?: Error) => {
        this.baseStore.onRequestFailed(errorKey, error, {
            errorMessage: this.t(
                'deal_view.comment_section.submit_comment_failure_message',
            ),
        });
    };

    deleteComment = async (commentId: string) => {
        try {
            await handleRequestAsync(
                this.commentsApi.deleteComment,
                { commentId },
                doNothing,
            );
            this.onDeleteResponse(commentId);
            return true;
        } catch (error) {
            this.baseStore.onRequestFailed('delete-comment', error as Error, {
                errorMessage: this.t(
                    'deal_view.comment_section.delete_comment_failure_message',
                ),
            });
            return false;
        }
    };

    onDeleteResponse = (commentId: string) => {
        this.toasterStore.showMessage({
            title: this.t(
                'deal_view.comment_section.delete_comment_success_message',
            ),
            type: 'success',
        });
        this.commentsStore.deleteComment(commentId);
    };

    onDeleteError = (error?: Error) => {
        this.baseStore.onRequestFailed('delete-comment', error, {
            errorMessage: this.t(
                'deal_view.comment_section.delete_comment_failure_message',
            ),
        });
    };
}
