import { type TFunction } from 'i18next';

export const getDeactivationConfirmationMessage = (
    t: TFunction<'translation', undefined>,
) => {
    return (
        <span>
            {t(
                'user_settings.search_subscriptions.deactivation_confirmation_message',
            )}
        </span>
    );
};
