import { makeAutoObservable } from 'mobx';
import { type TFunction } from 'i18next';
import { getI18n } from 'react-i18next';

import { AchievementService } from 'src/app-features/achievements/domain/service/achievements.service';
import { DealsApi } from 'src/data/api/deal/deal.api';
import {
    DealCreationSource,
    UserAchievementName,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { FolderTag } from 'src/data/api/graphql/br_project/generated/graphql-sdk';
import { LeadsApi } from 'src/data/api/leads/leads.api';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { CustomFieldsStore } from 'src/data/stores/custom-fields/custom-fields.store';
import { LeadsStore } from 'src/data/stores/leads/leads.store';
import { PipelineStore } from 'src/data/stores/pipeline/pipeline.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { SubscriptionsStore } from 'src/data/stores/subscriptions/subscriptions.store';
import { ToasterStore } from 'src/data/stores/toaster/toaster.store';
import { UserStore } from 'src/data/stores/user/user.store';
import { FolderType } from 'src/domain/models/folder/folder.model';
import { Language } from 'src/domain/models/locale/locale.model';
import {
    folderTagToVisibleFolder,
    Lead,
    UserFolderTag,
    UserFolderTagToFolderTagSdk,
} from 'src/domain/models/lead/lead.model';
import { getSearchTagForTracking } from 'src/presentation/modules/router/utils/route.utils';
import { doNothing } from 'src/utils/function.utils';
import {
    handleRequest,
    handleRequestAsync,
} from 'src/utils/handle-request.utils';

import {
    createDealSuccessMessage,
    getSearchNames,
    createSuccessMovedMessage,
} from './lead-actions.utils';

export interface LeadActionsFeature {
    isCreateLeadAndDealLoading: (docGroupId: string) => boolean;
    isLeadAndDealCreated: (docGroupId: string) => boolean;
    moveLeadToFolder: (
        lead: Lead,
        newFolder: UserFolderTag,
        pipelineId: string,
    ) => Promise<void>;
    createLeadAndDeal: (lead: Lead, pipelineId: string) => void;
    shouldRedirectAfterMoving: FolderType | null;
    clearFolderToRedirect: () => void;
    disqualifyLeadWithReason: (
        lead: Lead,
        reason?: string,
        additionalInfo?: string,
    ) => void;
}

const MOVED_LEAD_SNACKBAR_DURATION = 5000;
const PROJECT_LOOKUP_NAME = 'Project Lookup';
export class LeadActionsFeatureImpl implements LeadActionsFeature {
    t: TFunction<'translation', undefined> = getI18n().t;

    private isCreateLeadAndDealLoadingMap: Map<string, boolean> = new Map<
        string,
        boolean
    >();
    private isLeadAndDealCreatedMap: Map<string, boolean> = new Map<
        string,
        boolean
    >();
    public shouldRedirectAfterMoving: FolderType | null = null;

    public redirectAfterMoving = (folder: FolderType) => {
        this.shouldRedirectAfterMoving = folder;
    };

    public clearFolderToRedirect = () => {
        this.shouldRedirectAfterMoving = null;
    };

    constructor(
        private leadsStore: LeadsStore,
        private dealsApi: DealsApi,
        private leadsApi: LeadsApi,
        private pipelineStore: PipelineStore,
        private toasterStore: ToasterStore,
        private userStore: UserStore,
        private customFieldsStore: CustomFieldsStore,
        private mixpanelService: MixpanelService,
        private baseStore: IBaseStore,
        private achievementService: AchievementService,
        private subscriptionsStore: SubscriptionsStore,
    ) {
        makeAutoObservable(this);
    }

    onCreateDeal = (
        docGroupId: string,
        dealId: string | null,
        pipelineId: string,
    ) => {
        this.setIsCreateLeadAndDealLoading(docGroupId, false);
        this.isLeadAndDealCreatedMap.set(docGroupId, true);
        if (dealId) {
            this.pipelineStore.setNeedToRequestPipelines(true);
            const message = createDealSuccessMessage(
                dealId,
                pipelineId,
                this.t,
            );
            this.toasterStore.showMessage(message);
            this.achievementService.call(
                UserAchievementName.CreateYourFirstDeal,
            );
        }
    };

    get currentSearchName() {
        const tag = getSearchTagForTracking(window.location);
        if (isNaN(parseInt(tag))) {
            return tag;
        } else {
            return this.searchIdToName(parseInt(tag));
        }
    }

    getSearchIdNames = (searchIds: number[]): string[] => {
        if (this.currentSearchName === PROJECT_LOOKUP_NAME) {
            return [PROJECT_LOOKUP_NAME];
        } else {
            return searchIds.map(this.searchIdToName);
        }
    };

    searchIdToName = (searchId: number): string => {
        return (
            this.subscriptionsStore.subscriptionsList.find(
                (search) => search.id === searchId,
            )?.name ?? searchId.toString()
        );
    };

    createDeal = (
        docGroupId: string,
        projectId: string,
        projectTitle: string,
        pipelineId: string,
        creationSource: DealCreationSource,
    ) => {
        const pipeline = this.pipelineStore.getPipeline(pipelineId);
        if (pipeline) {
            const createDealRequest = (lead?: Lead) => {
                if (lead) {
                    const searchNames = getSearchNames(
                        lead,
                        this.userStore.user,
                    );

                    handleRequest(
                        this.dealsApi.createNewDeal,
                        {
                            pipelineId: pipeline.id,
                            projectId,
                            title: projectTitle,
                            currentSearchName: this.currentSearchName,
                            searchNames,
                            creationSource,
                        },
                        (dealId) =>
                            this.onCreateDeal(docGroupId, dealId, pipelineId),
                        doNothing,
                        (error) =>
                            this.baseStore.onRequestFailed(
                                'create-deal',
                                error,
                            ),
                    );
                }
            };

            // first get the lead data, then create deal.
            handleRequest(
                this.leadsApi.getLeadById,
                {
                    projectId: Number(projectId),
                    language: this.userStore.user?.language ?? Language.En,
                },
                createDealRequest,
                doNothing,
                (error) =>
                    this.baseStore.onRequestFailed('get-lead-by-id', error),
            );
        }
    };

    onSuccessLeadMove = (
        lead: Lead,
        newFolder: UserFolderTag,
        pipelineId?: string,
        rejectionReason?: {
            reason?: string;
            additionalInfo?: string;
        },
    ) => {
        const newLead = { ...lead, userFolderTag: newFolder };
        this.leadsStore.set(newLead);
        this.leadsStore.removeId(lead.id);

        const folder = folderTagToVisibleFolder[newFolder];
        const message = createSuccessMovedMessage(this.t);

        // create a new deal if lead moved to interesting folder. Else continue with normal snackbar update
        if (folder === FolderType.interesting && pipelineId) {
            const customTitle = this.customFieldsStore.titleMap.get(lead.id);
            const projectTitle = customTitle || lead.mergedDocument.title;
            this.createDeal(
                lead.id,
                lead.id,
                projectTitle,
                pipelineId,
                DealCreationSource.Screening,
            );
        } else {
            this.toasterStore.showMessage({
                ...message,
                autoClose: MOVED_LEAD_SNACKBAR_DURATION,
            });
            this.mixpanelService.trackEvent(MixpanelEventName.RejectedProject, {
                projectId: lead.id,
                projectName: lead.mergedDocument.title,
                rejectionReasonSelection: rejectionReason?.reason ?? '',
                rejectionReasonText: rejectionReason?.additionalInfo ?? '',
                currentSearchName: this.currentSearchName,
                associatedSearches: this.getSearchIdNames(lead.searchIds),
            });
        }
    };

    isCreateLeadAndDealLoading = (docGroupId: string): boolean => {
        return this.isCreateLeadAndDealLoadingMap.get(docGroupId) ?? false;
    };

    isLeadAndDealCreated = (docGroupId: string): boolean => {
        return this.isLeadAndDealCreatedMap.get(docGroupId) ?? false;
    };

    setIsCreateLeadAndDealLoading = (
        docGroupId: string,
        isLoading: boolean,
    ) => {
        this.isCreateLeadAndDealLoadingMap.set(docGroupId, isLoading);
    };

    onSubmitError = (errorKey: string, error?: Error) => {
        this.baseStore.onRequestFailed(errorKey, error, {
            errorMessage: this.t('lead.move_lead_failure_message'),
        });
    };

    createLead = (
        docGroupId: string,
        pipeDbCollection: string,
        setValue: (value: number) => void,
    ) => {
        handleRequest(
            this.leadsApi.createLeadAsInteresting,
            {
                docGroupsInfo: [
                    { id: docGroupId, collection: pipeDbCollection },
                ],
            },
            setValue,
            doNothing,
            (error) => this.baseStore.onRequestFailed('create-lead', error),
        );
    };

    createLeadAndDeal = (lead: Lead, pipelineId: string) => {
        if (!lead.docGroupData) {
            return;
        }
        const { id: docGroupId, mergedDocument, docGroupData } = lead;
        const { title: projectTitle } = mergedDocument;
        const { pipeDbCollection } = docGroupData;
        this.setIsCreateLeadAndDealLoading(docGroupId, true);

        const createLeadPromise: Promise<number> = new Promise((resolve) => {
            this.createLead(docGroupId, pipeDbCollection, resolve);
        });
        createLeadPromise.then((newLeadId: number) =>
            this.createDeal(
                docGroupId,
                newLeadId.toString(),
                projectTitle,
                pipelineId,
                DealCreationSource.ProjectLookup,
            ),
        );
    };

    moveLeadToFolder = async (
        lead: Lead,
        newFolder: UserFolderTag,
        pipelineId: string,
    ) => {
        if (!this.userStore.user) {
            return;
        }

        await handleRequestAsync(
            this.leadsApi.moveLead,
            {
                projectId: Number(lead.id),
                folder: UserFolderTagToFolderTagSdk[newFolder] as FolderTag,
            },
            doNothing,
            (error) => this.onSubmitError('move-lead-to-folder', error),
        );

        this.onSuccessLeadMove(lead, newFolder, pipelineId);
    };

    disqualifyLeadWithReason = (
        lead: Lead,
        reason?: string,
        additionalInfo?: string,
    ) => {
        handleRequest(
            this.leadsApi.moveLead,
            {
                projectId: Number(lead.id),
                folder: FolderTag.NotInteresting,
                disqualificationReason: reason,
                disqualificationExtraInfo: additionalInfo,
            },
            () =>
                this.onSuccessLeadMove(
                    lead,
                    UserFolderTag.notInteresting,
                    undefined,
                    {
                        reason,
                        additionalInfo,
                    },
                ),
            doNothing,
            (error) => this.onSubmitError('disqualify-lead-with-reason', error),
        );
    };
}
