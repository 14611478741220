import { type TFunction } from 'i18next';
import { getI18n } from 'react-i18next';

import { ToasterMessage } from 'src/domain/features/toaster/toaster.utils';
import { history } from 'src/history.utils';

export const buildLoginErrorMessage = (): ToasterMessage => {
    const t: TFunction<'translation', undefined> = getI18n().t;

    return {
        title: t('auth.login_failure_title'),
        message: t('auth.wrong_email_or_password_message'),
        type: 'warning',
        primaryActionText: t(
            'auth.wrong_email_or_password_primary_action_text',
        ),
        primaryAction: () => history.push('/recover_password'),
    };
};

export const buildInactiveErrorMessage = (): ToasterMessage => {
    const t: TFunction<'translation', undefined> = getI18n().t;

    return {
        title: t('auth.error.deactivated.title'),
        message: t('auth.error.deactivated.message'),
        type: 'warning',
        primaryActionText: t('auth.error.deactivated.button'),
        primaryAction: () =>
            (window.location.href = 'mailto:customersuccess@buildingradar.com'),
    };
};

export const buildCreateNewPasswordErrorMessage = (): ToasterMessage => {
    const t: TFunction<'translation', undefined> = getI18n().t;

    return {
        title: t('auth.set_new_password_failure_title'),
        message: t('auth.set_new_password_failure_message'),
        type: 'warning',
        primaryActionText: t(
            'auth.set_new_password_failure_primary_action_text',
        ),
        primaryAction: () => history.push('/recover_password'),
    };
};

export const sessionCookieExists = () => {
    /**
     * regex for finding the session cookie ("session="),
     * could be at the beginning of the string (^)
     * or after a semicolon (;),
     * with 0 or more leading space (\s)/
     */
    const cookieRegex = /(?:^|;)\s*session=/;
    const cookieString = document.cookie;
    return cookieRegex.test(cookieString);
};
