import { makeAutoObservable } from 'mobx';
import { type TFunction } from 'i18next';
import { getI18n } from 'react-i18next';

import { FolderCountersApi } from 'src/data/api/folder-counters/folder-counters.api';
import { LeadsApi } from 'src/data/api/leads/leads.api';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { FolderCountersStore } from 'src/data/stores/folder-counters/folder-counters.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { ToasterStore } from 'src/data/stores/toaster/toaster.store';
import { UserStore } from 'src/data/stores/user/user.store';
import {
    FolderCounters,
    FolderType,
} from 'src/domain/models/folder/folder.model';
import { Search } from 'src/domain/models/search/search.model';
import { sortNewArray } from 'src/utils/array.utils';
import {
    Cancellable,
    handleRequest,
    handleRequestAsync,
} from 'src/utils/handle-request.utils';

export interface NavbarFeature {
    allSearches: Search[];
    folderCounters: FolderCounters;
    isLoading: boolean;
    allSubFolderCount: (folder: FolderType) => number;
    isMobileMenuOpened: boolean;
    toggleMobileMenu: (flag?: boolean) => void;
    requestAllFolderCounters: () => Cancellable;
    wipeInboxedSearchProjects: (
        searchId: number,
        folder: FolderType,
    ) => Promise<void>;
    trackSearchNarrativeEvents: (
        eventName:
            | MixpanelEventName.ClickedToViewSearchNarrative
            | MixpanelEventName.RequestedSearchNarrativeUpdate,
        currentSearchName: string,
        currentSearchNarrative?: string,
    ) => void;
}

export class NavbarFeatureImpl implements NavbarFeature {
    isMobileMenuOpened = false;
    t: TFunction<'translation', undefined> = getI18n().t;

    get user() {
        return this.userStore.user;
    }

    get allSearches(): Search[] {
        return this.user
            ? sortNewArray(this.user.searches)((a, b) =>
                  a.name.localeCompare(b.name),
              )
            : [];
    }

    get isLoading(): boolean {
        return this.folderCountersStore.isLoading;
    }

    get folderCounters(): FolderCounters {
        return this.folderCountersStore.folderCounters;
    }

    constructor(
        private folderCountersApi: FolderCountersApi,
        private userStore: UserStore,
        private folderCountersStore: FolderCountersStore,
        private leadsApi: LeadsApi,
        private toasterStore: ToasterStore,
        private baseStore: IBaseStore,
        private mixPanelService: MixpanelService,
    ) {
        makeAutoObservable(this);
    }

    toggleMobileMenu = (flag?: boolean) => {
        const newValue = flag !== undefined ? flag : !this.isMobileMenuOpened;
        this.isMobileMenuOpened = newValue;
    };

    requestAllFolderCounters = (): Cancellable => {
        return handleRequest(
            this.folderCountersApi.getFolderCounters,
            { searchTags: this.userStore.searchTags.map((tag) => Number(tag)) },
            this.folderCountersStore.setFolderCounters,
            this.folderCountersStore.setLoading,
            (error) =>
                this.baseStore.onRequestFailed(
                    'request-folder-counters',
                    error,
                ),
        );
    };

    allSubFolderCount = (folder: FolderType): number => {
        const folderValues = Object.values(this.folderCounters[folder]);
        if (!folderValues.length) {
            return 0;
        }
        return folderValues.reduce((total, current = 0) => total + current);
    };

    wipeInboxedSearchProjects = async (
        searchId: number,
        folder: FolderType,
    ) => {
        try {
            await handleRequestAsync(this.leadsApi.wipeInboxedSearchProjects, {
                searchId,
            });

            delete this.folderCounters[folder][searchId];

            this.toasterStore.showMessage({
                title: this.t(
                    'screening.search_list.configure_narrative.clear_inbox_success_message',
                ),
            });
        } catch (error) {
            this.baseStore.onRequestFailed(
                'wipe-inboxed-search-projects',
                error as Error,
            );
        }
    };

    trackSearchNarrativeEvents = (
        eventName:
            | MixpanelEventName.ClickedToViewSearchNarrative
            | MixpanelEventName.RequestedSearchNarrativeUpdate,
        currentSearchName: string,
        currentSearchNarrative?: string,
    ) => {
        this.mixPanelService.trackEvent(eventName, {
            currentSearchName,
            currentSearchNarrative,
        });
    };
}
